<template>
  <Modal @close="$emit('close')">
    <template v-slot:header>
      <h3>Récupérer son mot de passe</h3>
    </template>
    <template v-slot:body>
      <template v-if="!mail_is_sent">
        <p>
          Renseigne ici l’adresse mail que tu as utilisé pour t’inscrire. Un
          mail sera envoyé à cette adresse avec un nouveau mot de passe pour se
          connecter.
        </p>
        <form class="_form" @submit.prevent="resetPass">
          <div class="fillField">
            <label>
              Ton adresse mail
            </label>
            <input
              type="email"
              name="EMAIL"
              class="is--required"
              placeholder=""
              required=""
              v-model="user_mail"
              ref="mailField"
              :class="{
                'is--invalid':
                  this_mail_doesnt_exist &&
                  this_mail_doesnt_exist === user_mail,
              }"
            />
            <small
              v-if="
                this_mail_doesnt_exist && this_mail_doesnt_exist === user_mail
              "
            >
              Cette adresse mail ne possède pas de compte associé.
            </small>
          </div>

          <input type="submit" value="Valider" class="" />
        </form>
        <div v-if="is_loading" class="_loaderCont">
          <div class="_loader" key="loader">
            Chargement…
          </div>
        </div>
      </template>
      <div class="mainText" v-else>
        <p class="">
          Un mail avec des instructions a été envoyé sur
          <strong>{{ user_mail }}</strong> (pense bien à vérifier aussi dans les
          spams).
        </p>
        <p>
          S’il n’arrive pas au bout de quelques minutes, tu peux aussi nous
          contacter sur
          <a href="mailto:reserves@fraciledefrance.com">
            reserves@fraciledefrance.com</a
          >
          depuis l’adresse mail associé au compte.
        </p>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from "../subcomponents/Modal.vue";

export default {
  props: {
    mail: String,
  },
  components: { Modal },
  data() {
    return {
      is_loading: false,
      mail_is_sent: false,

      message: "",
      response: false,

      user_mail: this.mail,
      this_mail_doesnt_exist: "",
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    resetPass() {
      this.is_loading = true;
      this.resetPassCurrentUser({ email: this.user_mail })
        .then(() => {
          this.is_loading = false;
          this.mail_is_sent = true;
        })
        .catch((err) => {
          this.is_loading = false;
          if (err === "not_found") this.this_mail_doesnt_exist = this.user_mail;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
._loaderCont {
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.85);
  z-index: 100000;
}
</style>
