<template>
  <nav
    class="m_topbar"
    :class="{
      'is--movedUp': is_moved_up,
      'is--scrolled': $root.scroll_value > scroll_threshold,
    }"
  >
    <!-- @mouseenter.self="is_hovered = true"
    @mouseleave.self="is_hovered = false" -->
    <div class="m_topbar--content">
      <div class="m_topbar--content--title">
        <span class="_navLink" v-if="!$root.is_homepage">
          <router-link to="/">
            <SDTRLogo class="_logo" :staggered_animation="false" />
          </router-link>
        </span>
        <a
          class="_fracLogo"
          href="https://www.fraciledefrance.com/"
          target="_blank"
        >
          <FracLogo />
        </a>
      </div>

      <template v-if="$root.is_narrow_device">
        <button
          type="button"
          class="_hamburger"
          @click="show_menu = !show_menu"
        >
          <svg
            class="svg-icon"
            viewBox="0 0 20 20"
            x="0px"
            y="0px"
            width="35"
            height="35"
          >
            <path
              fill="currentColor"
              d="M3.314,4.8h13.372c0.41,0,0.743-0.333,0.743-0.743c0-0.41-0.333-0.743-0.743-0.743H3.314
								c-0.41,0-0.743,0.333-0.743,0.743C2.571,4.467,2.904,4.8,3.314,4.8z M16.686,15.2H3.314c-0.41,0-0.743,0.333-0.743,0.743
								s0.333,0.743,0.743,0.743h13.372c0.41,0,0.743-0.333,0.743-0.743S17.096,15.2,16.686,15.2z M16.686,9.257H3.314
								c-0.41,0-0.743,0.333-0.743,0.743s0.333,0.743,0.743,0.743h13.372c0.41,0,0.743-0.333,0.743-0.743S17.096,9.257,16.686,9.257z"
            ></path>
          </svg>
        </button>

        <div class="_smMenu" v-if="show_menu">
          <div class="_smMenu--overlay" @click="show_menu = false" />
          <div class="_smMenu--content">
            <div class="_noMargins">
              <button
                type="button"
                class="modalClose"
                aria-label="Close"
                @click="show_menu = !show_menu"
              >
                &times;
              </button>
            </div>
            <SearchBar
              :show_advanced_search_button="false"
              :search_button_can_be_active="false"
            />
            <router-link to="/participer">participer</router-link>
            <router-link to="/explorer">explorer la collection</router-link>
            <router-link to="/accrochages">les accrochages</router-link>
            <router-link
              to="/mon-compte"
              class="_accountLink"
              :class="{
                'is--loggedin': $root.is_logged_in,
              }"
            >
              <template v-if="$root.is_login_as">
                <div class="_loader" key="loader">…</div>
              </template>
              <template v-else-if="!$root.is_logged_in">
                mon compte
              </template>
              <template v-else>{{ $root.user_login.name }}</template>
            </router-link>

            <PageFooter />
          </div>
        </div>
      </template>
      <template v-else>
        <transition name="fade">
          <div
            class="m_topbar--content--search"
            v-if="!$root.is_homepage && $route.name !== 'Rechercher'"
          >
            <SearchBar
              :show_advanced_search_button="false"
              :search_button_can_be_active="false"
            />
          </div>
        </transition>

        <nav class="m_topbar--content--buttons">
          <router-link to="/participer">participer</router-link>
          <router-link to="/explorer">explorer la collection</router-link>
          <router-link to="/accrochages">les accrochages</router-link>
          <div class="_navVerticalSep" />
          <router-link
            to="/mon-compte"
            class="_accountLink"
            :class="{
              'is--loggedin': $root.is_logged_in,
            }"
          >
            <template v-if="$root.is_login_as">
              <div class="_loader" key="loader">…</div>
            </template>
            <template v-else-if="!$root.is_logged_in">
              mon compte
            </template>
            <template v-else>{{ $root.user_login.name }}</template>
          </router-link>
        </nav>

        <div class="_hoverZone" v-if="is_moved_up" />
      </template>
    </div>
  </nav>
</template>
<script>
import SearchBar from "./subcomponents/SearchBar.vue";
import PageFooter from "./PageFooter.vue";

export default {
  props: {},
  components: {
    SearchBar,
    PageFooter,
    // Timer,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      // is_hovered: false,
      scroll_threshold: 70,

      show_menu: false,
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  watch: {
    $route: {
      handler() {
        this.show_menu = false;
      },
    },
    show_menu() {
      if (this.show_menu) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "";
      }
    },
    "$root.is_narrow_device"() {
      if (!this.is_narrow_device && this.show_menu) {
        this.show_menu = false;
      }
    },
  },
  computed: {
    is_moved_up() {
      return (
        this.$root.scroll_value > this.scroll_threshold &&
        // !this.is_hovered &&
        !this.$root.scrolling_up
      );
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.m_topbar {
  position: sticky;
  width: 100%;
  top: 0;
  // background-color: rgba(255, 255, 255, 0.4);
  // color: white;
  z-index: 1000;
  pointer-events: auto;

  // min-height: 80px;

  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);

  #app.is--homePage & {
    position: fixed;
    // min-height: 0px;
  }
}

.m_topbar {
  &.is--movedUp {
    .m_topbar--content {
      transform: translateY(-100%);
      opacity: 0;
    }
  }
  &.is--scrolled {
    .m_topbar--content {
      // transition: none;
    }
  }
}

._hoverZone {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  left: 0;
  top: 99%;
  height: 50px;
  z-index: -1;
}

.m_topbar--content {
  // max-width: 800px;
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing);
  min-height: 70px;

  background: white;
  color: black;
  padding: calc(var(--spacing) / 2) calc(var(--spacing) / 1);
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);

  #app.is--homePage & {
    background: transparent;
    color: white;
  }
  #app.is--homePage .m_topbar.is--scrolled & {
    background: black;
  }

  > * {
  }

  > .m_topbar--content--title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: var(--spacing);

    flex: 1 0 auto;
  }

  > .m_topbar--content--search {
    flex: 1 5 auto;
    max-width: 320px;
  }

  > .m_topbar--content--buttons {
    flex: 1 0 auto;
    // max-width: 580px;
  }
}

.m_topbar--content--title {
}

.m_topbar--content--search {
}

._searchBar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: rgba(220, 220, 220, 0.4);
  border-radius: 1.4em;
  padding: 0.25em 1em;

  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);

  &:hover {
    background-color: rgba(220, 220, 220, 0.8);
  }

  input {
    background: transparent;
    border: none;
  }

  button {
    background: none;
  }

  > * {
    padding: 0.25em;
  }

  svg {
    width: 1em;
    height: 1em;
  }
}

.m_topbar--content--buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: calc(var(--spacing) / 4);

  a {
    // padding: 0.2em 0.6em;
    padding: calc(var(--spacing) / 1) calc(var(--spacing) / 2);
    white-space: nowrap;
    // border-radius: 1em;
    // background: blue;

    &:hover,
    &:focus,
    &.router-link-active {
      text-decoration-line: underline;
    }
  }

  ._loader {
    font-size: 0.3rem;
    display: inline-block;
    margin: 0;
  }
}

._navVerticalSep {
  width: 1px;
  height: 2em;
  background: #ccc;
}

.searchBar--icon {
  padding: calc(var(--spacing) / 2);
  border-radius: 50%;
}

._navLink ::v-deep a {
  display: block;
  padding: calc(var(--spacing) / 2) 0;
  // padding-left: calc(var(--spacing));

  svg {
    width: 60px;
  }
}

._hamburger {
  width: 3em;
  height: 3em;
  padding: calc(var(--spacing) / 1.5);
  border-radius: 50%;
  background: transparent;
}
.is--loggedin {
  display: inline-block;
  background: var(--red);
  padding: calc(var(--spacing) / 2);
}
._fracLogo {
  display: block;
  line-height: 0;
  margin-top: -9px;
  margin-bottom: -8px;
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);

  svg {
    width: 100px;
    height: 62px;

    transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  }

  #app.is--homePage .m_topbar:not(.is--scrolled) & {
    margin-top: 0;
    svg {
      width: 120px;
      height: 74px;
    }
  }
}

._smMenu {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;

  ._smMenu--content {
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    left: auto;
    margin-left: calc(var(--spacing) * 2);
    max-width: 320px;
    background: white;
    color: black;
    padding: calc(var(--spacing) / 1) calc(var(--spacing) * 1.5);
    box-shadow: 0 0 1rem 0rem rgba(41, 41, 41, 0.25);
    text-align: center;

    overflow-y: auto;

    > *:not(._noMargins) {
      display: block;
      padding: calc(var(--spacing) / 1) 0;
    }

    .m_pagefooter {
      // margin-top: calc(var(--spacing) * 2);
    }
  }

  ._smMenu--overlay {
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 0.85);
    cursor: pointer;
  }
}

.modalClose {
  right: 0;
  position: relative;
  margin: 0;
  margin-left: auto;
  margin-right: 0;
  // margin-bottom: calc(var(--spacing) * 2);
  left: auto;
  z-index: 150;
  // width: 1.5em;
  // height: 1.5em;
  padding: calc(var(--spacing) / 1.5);
}

._accountLink._accountLink {
  padding: calc(var(--spacing) / 2) calc(var(--spacing) / 1);
  border-radius: 1.125rem;
}
</style>
